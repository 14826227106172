import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpenCallsComponent } from './open-calls/open-calls.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule as RootSharedModule } from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, TranslateModule, RootSharedModule],
  declarations: [OpenCallsComponent],
  exports: [OpenCallsComponent]
})
export class SharedModule { }
