import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SideBarComponent {
  showSideBar = false;

  constructor() { }

  toggleSideBar() {
    this.showSideBar = !this.showSideBar;
  }
}
