import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-pulse',
  templateUrl: './pulse.component.html',
  styleUrls: ['./pulse.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PulseComponent {
  constructor() { }
}
