export enum DeviceStatusSearch {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  LOCKED = 'LOCKED',
  BATTERY = 'BATTERY',
  NO_MEMORY = 'NO_MEMORY',
  NO_PAPER = 'NO_PAPER',
  ALARMED_INPUT = 'ALARMED_INPUT',
  OFFLINE_MODULE = 'OFFLINE_MODULE'
}
