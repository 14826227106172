<div class="page-error">
    <div class="page-error--title">
        <label>{{pageTitle}}</label>
    </div>
    <div class="page-error--sub-title">
        <label>{{pageSubTitle}}</label>
    </div>
    <div class="page-error--content">
        <label>{{pageContent}}</label>
    </div>
</div>