import { Injectable } from '@angular/core';
import { timer, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';

@Injectable()
export class UtilsService {

  public formatDate(date: any) {
    // Gambi: existem dois serviços e cada um retorna a data em um formato.
    if (date.includes('Z')) {
      date = date.replace('Z', '');
    } else {
      date = date.split('+')[0];
    }
    return timer(0, 60000).pipe(
      switchMap(() => of(moment(date).format('L LT'))));
  }

}
