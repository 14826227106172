import { Directive, ElementRef, HostListener, Input, Renderer2, OnChanges } from '@angular/core';

@Directive({
  selector: '[appTextFill]'
})
export class TextFillDirective implements OnChanges {

  @Input() defaultFontSize: number;
  @Input() model: number;

  private maxWidth = 100;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2) {
  }

  ngOnChanges() {
    setTimeout(() => {
      this.changeFontSizeElement();
    });
  }

  @HostListener('window:resize') onResize() {
    this.changeFontSizeElement();
  }

  changeFontSizeElement() {
    if (this.el.nativeElement.offsetWidth > this.el.nativeElement.parentNode.offsetWidth) {
      const fontSize = (this.el.nativeElement.parentNode.offsetWidth / this.el.nativeElement.offsetWidth) * this.convertPxToVh(parseInt(window.getComputedStyle(this.el.nativeElement).fontSize));
      if (fontSize > this.defaultFontSize) {
        this.renderer.setStyle(this.el.nativeElement, 'fontSize', this.defaultFontSize + 'vh');
      } else {
        this.renderer.setStyle(this.el.nativeElement, 'fontSize', fontSize + 'vh');
      }
    } else if ((this.el.nativeElement.parentNode.offsetWidth - this.el.nativeElement.offsetWidth) > this.maxWidth) {
      this.renderer.setStyle(this.el.nativeElement, 'fontSize', this.defaultFontSize + 'vh');
    }
  }

  convertPxToVh(px) {
    return (px * 100) / window.innerHeight;
  }

}
