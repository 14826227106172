import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

@Injectable()
export class OpenEntriesService {
  private endpoints = {
    allOpenEntries: `${
      environment.urlBackend
      }/virtuallobby/{virtualLobbyId}/allpeople/inside`,
    openEntriesByTerm: `${
      environment.urlBackend
      }/virtuallobby/{virtualLobbyId}/people/inside`
  };

  constructor(private http: HttpClient) { }

  public getAllOpenEntries(virtualLobbyId: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('page', '1');
    params = params.append('pageSize', '100');
    const endpoint = this.endpoints.allOpenEntries.replace(
      '{virtualLobbyId}',
      virtualLobbyId
    );
    return this.http.get<any[]>(endpoint, { params });
  }

  public getOpenEntriesByTerm(
    term: string,
    virtualLobbyId: string
  ): Observable<any[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('term', term);
    queryParams = queryParams.append('page', '1');
    queryParams = queryParams.append('pageSize', '20');
    const endpoint = this.endpoints.openEntriesByTerm.replace(
      '{virtualLobbyId}',
      virtualLobbyId
    );
    return this.http.get<any[]>(endpoint, { params: queryParams });
  }
}
