import { Component, ViewEncapsulation, Input } from '@angular/core';

@Component({
  selector: 'app-page-error',
  templateUrl: 'page-error.component.html',
  styleUrls: ['page-error.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class PageErrorComponent {
  @Input() pageTitle = '';
  @Input() pageSubTitle = '';
  @Input() pageContent = '';
}
