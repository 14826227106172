import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environment/environment';
import { IrregularPresences } from '@monitoring/irregular-presences/models/irregular-presences.model';
import { ValidationRule } from '@monitoring/irregular-presences/enums/validation-rule.enum';

@Injectable()
export class IrregularPresencesService {
  private sdlQueriesEndpoints = `${environment.urlSdl}/sam/monitor/queries`;
  private sdlEndpoints = {
    getIrregularPresences: `${this.sdlQueriesEndpoints}/getIrregularPeoplePresence`
  };

  constructor(private http: HttpClient) { }

  public getIrregularPresences(
    physicalLocationId: number,
    validationRule: ValidationRule,
    roleIds?: any
  ): Observable<IrregularPresences> {
    return this.http.post<IrregularPresences>(this.sdlEndpoints.getIrregularPresences, {
      validationRule,
      physicalLocationId,
      roleIds
    });
  }

}
