import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DateTimeFormatService } from '@core/date-time-format/date-time-format.service';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  constructor(private dateTimeFormatService: DateTimeFormatService) { }

  transform(date: any, _?): string {
    const datePipe = new DatePipe('en-US');
    date = datePipe.transform(
      date,
      this.dateTimeFormatService.getDateTimeFormat()
    );
    return date;
  }
}
