import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Component({
  selector: 'app-device-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreeComponent {
  @Input() value: any[];
  @Output() onNodeSelect: EventEmitter<any> = new EventEmitter();

  selectNode($event) {
    this.onNodeSelect.emit($event);
  }
}
